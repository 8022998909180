import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "The Value of Your Military Experience In the Workforce",
  "author": "Purepost",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "the-value-of-your-military-experience-in-the-workforce",
  "draft": false,
  "meta_title": "The Value of Your Military Experience In the Workforce"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Military experience is complex. Trying to explain it to someone who has never been a part of the community or even known a soldier, personally or professionally, is even more complex. When you sit down to look at career exploration, start with resume translation and interview preparation.`}</p>
    <p>{`Breaking down the process into two phases can be helpful. The resume will be the written, first impression that a potential employer sees as it slides onto the desk amongst possibly hundreds of other qualified applicants. The interview is your opportunity to present yourself as the qualified, professional you are — in person, in real-time.`}</p>
    <p>{`So when you’ve made it through the application process, translated resume in hand, you look good on paper, but what other qualities may an employer be interested in talking about in person that you can directly relate to from the framework of your military experience?`}</p>
    <p><strong parentName="p">{`Deadlines`}</strong></p>
    <p>{`A mission-oriented life means deadlines are non-negotiable. There is most likely a sequence of events either happening, about to happen, or already happened and in review and your task falls or fell somewhere on that spectrum. Every day of your life has involved deadlines that were a part of a much larger objective. While the civilian workforce holds deadlines in high regard as well, your ability to respect, meet, and go above and beyond will supersede the majority of your civilian counterparts. Bottom line: `}<em parentName="p">{`You know how to get the job done.`}</em></p>
    <p><strong parentName="p">{`Teamwork`}</strong></p>
    <p>{`A successful military career breaks down to your ability to work as an effective, functioning member of a team. The level to which this meant life and death during your time in the military is paramount and has shaped your definition of a team for life. While your future civilian career and the teams in which you’ll interact may not function at such a high level, your success will still lie on the basic ability to work well in a team. Effective communication, respecting opinions, and appropriately executed actions add great value to all career experiences.`}</p>
    <p><strong parentName="p">{`Community`}</strong></p>
    <p>{`A large part of your military career has been laced in and through a community. You have thrived not only in a work environment that required teamwork but a larger community that knows the importance of family, communication, outreach services, and looking out for your fellow man. This heightened awareness and sense of community may not be as commonplace in a civilian work environment, but your ability to connect, give back, and be socially aware of work and social events will position you well as someone who is `}<a parentName="p" {...{
        "href": "http://blog.purepost.co/tips-to-overcome-culture-shock-after-the-army"
      }}>{`cognizant to the people, places, and events`}</a>{` surrounding them.`}</p>
    <p>{`Now that you better understand the real value of your experience, take the first step towards transitioning into a civilian job! We're here to ensure it's as easy as possible.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      